import React from "react";

const Footer = () => {
  return (
    <div>
      <div>
        &copy; 2024 Copyright
        <strong>
          <span> Team IT Medilab</span>
        </strong>
        . All Rights Reseved
      </div>
    </div>
  );
};

export default Footer;
