export default [
  {
    id: 1,
    uid: "hdjafkafaf",
    name: "Document",
    owner: "user",
    created_at: "12-07-2023",
    updated_at: "12-08-2023",
  },
  {
    id: 2,
    uid: "hdjsadafkafaf",
    name: "Document 2",
    owner: "user 1",
    created_at: "12-07-2023",
    updated_at: "12-08-2023",
  },
];
