import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalHeader } from 'react-bootstrap'

const AddModalRab = ({show, handleClose}) => {
    const [inputData, setInputData] = useState({})
    const [inputEst, setInputEst] = useState("");

    const handleInput = (e) => {
        setInputData({
            ...inputData,
            [e.target.name]:e.target.value
        })
    }

    const handleInputDataRP = (event) => {
        const rawValue = event.target.value;
        const formattedValue = formatCurrency(rawValue);
        setInputEst(formattedValue);
      };
    
      const formatCurrency = (value) => {
        const sanitizedValue = value.replace(/[^\d]/g, "");
        const formattedValue = Number(sanitizedValue).toLocaleString("id-ID");
        return formattedValue;
      };
      const [resultValue, setResultValue] = useState();

      const inputWithoutSeparator = inputEst.length > 0 ? inputEst.replace(/\./g, "") : "";
      const tempOne = parseFloat(inputWithoutSeparator);
      const tempTwo = parseFloat(inputData.qty);
    
      useEffect(() => {
        const result_estimasi_charge = tempOne * tempTwo;
        setResultValue(result_estimasi_charge);
      }, [tempOne, tempTwo]);

      const handleSubmit = (e) => {
        e.preventDefault();
        if (Object.keys(inputData).length > 0) {
          const dataExist = JSON.parse(localStorage.getItem("rabEdit")) || [];
          const id = `data_${Date.now()}`;
          const Payload = {
            id: id,
            item_uid: inputData.item_uid,
            is_alkes: inputData.is_alkes,
            estimated_cost: tempOne,
            qty: inputData.qty,
            total_estimated_cost: resultValue,
            realization_note: inputData.realization_note,
          };
          dataExist.push(Payload);
          localStorage.setItem("rabEdit", JSON.stringify(dataExist));
          setInputData({
            item_uid: "",
            estimated_cost: 0,
            realization_note: "",
            qty: "",
          });
          setInputEst(0);
          handleClose();
        }
      };
    
  return (
  <>
 <Modal show={show} onHide={handleClose} placement="end">
      <Modal.Header closeButton>
        <Modal.Title>Tambah Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            id="floatingInput"
            name="item_uid"
            onChange={handleInput}
            placeholder="name@example.com"
          />
          <label for="floatingInput">Item</label>
        </div>
        <div className="mb-3">
          <label htmlFor="">Apakah Alat Kesehatan</label>
          <select
            name="is_alkes"
            id=""
            className="form-control"
            onChange={handleInput}
          >
            <option value="">Select Choose</option>
            <option value="yes">Iya</option>
            <option value="no">Tidak</option>
          </select>
        </div>
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            value={inputEst || 0}
            onChange={handleInputDataRP}
            id="floatingInput"
            placeholder="name@example.com"
          />
          <label for="floatingInput">Nilai Estimasi Biaya</label>
        </div>
        <div class="form-floating mb-3">
          <input
            type="number"
            class="form-control"
            name="qty"
            onChange={handleInput}
            id="floatingInput"
            placeholder="name@example.com"
          />
          <label for="floatingInput">Qty</label>
        </div>
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            name="total_estimasi_biaya"
            value={resultValue || 0}
            id="floatingInput"
            placeholder="name@example.com"
          />
          <label for="floatingInput">Total Estimasi Biaya</label>
        </div>
        <div className="mb-3">
          <label htmlFor="" style={{ fontWeight: "600" }}>
            Catatan Realisasi
          </label>
          <textarea
            id=""
            cols="30"
            rows="5"
            name="realization_note"
            onChange={handleInput}
            className="form-control"
          ></textarea>
        </div>
      </Modal.Body>
      <Modal.Footer className="mb-4">
        <Button variant="secondary" className="me-2" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" className="me-3" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  </>
  )
}

export default AddModalRab