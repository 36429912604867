import React from "react";

const BreadcrumbDealsVersion = () => {
  return (
    <div className="row">
      <div className="col">
        <div className="pagetitle">
          <h1>Deals Version</h1>
          <nav>
            <ol className="breadcrumb mt-2">
              <li className="breadcrumb-item">
                <a href="/" className="text-decoration-none">
                  Dashboard
                </a>
              </li>
              <li className="breadcrumb-item active fw-bold">Deals Version</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default BreadcrumbDealsVersion;
