import React from 'react'

export const columnsDataTable = [
    {
        name:"Deals Version",
        selector:(row) => row.name
    },
    {
        name:"Active"
    }
]
